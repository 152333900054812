import React, { useState, useEffect, useRef } from 'react';
import './chatsection.css';
import SessionTimer from '../../ReUsedComponents/sessionTimer/SessionTimer';
import Input from '../../ReUsedComponents/inputField/Input';
import SubmitButton from '../../ReUsedComponents/submitButton/SubmitButton';
import FileInput from '../../ReUsedComponents/inputField/FileInput';
import TextArea from '../../ReUsedComponents/textareaField/TextArea'
import dateFormat from 'dateformat';
import { useSelector, useDispatch} from 'react-redux';
import { getApprovedTemplates, sendTemplate, getTemplateHeaderMediaUrl } from '../../../apis/TemplatesAPI';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const ChatSection = ({ allAgents, messagesData, handleChatMessageChange, sendChatMessage, chatMessage, changeConvoStatus, assingAgent, sessionInfo, 
  sessionExpired, keyDownCreateNewLine, contactDetails }) => {

  const dispatch = useDispatch()
  const selector = useSelector((state) => state.session);
  const action = useSelector((state) => state.action)

  const [sessionExpiration, setSessionExpiration] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(sessionInfo?.assignedUser?.id);
  const [selectedStatus, setSelectedStatus] = useState(sessionInfo?.sessionStatus);
  const [viewTemplate, setViewTemplate] = useState(false)
  const [templateData, setTemplateData] = useState()

  const [allTemplates, setAllTemplates] = useState([]);
  const [bodyTemplateVariables, setBodyTemplateVariables] = useState([]);
 
  const messagesEndRef = useRef(null);

  // const [filteredItems, setFilteredItems] = useState([]);
  // const [searchFilter, setSearchFilter] = useState('');
  const [sendTempModal, setSendTempModal] = useState(false)

  const chatStatusList = [
    {title: "Open", value: "OPEN"},
    {title: "Pending", value: "PENDING"},
    {title: "Solved", value: "SOLVED"},
    {title: "Expired", value: "EXPIRED"},
  ];

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (sessionInfo?.expiresAt) {
      const expirationTime = new Date(sessionInfo.expiresAt).getTime();
      setSessionExpiration(expirationTime);

      const checkExpiration = () => {
        // const now = new Date().getTime();
      };
      checkExpiration();
      const interval = setInterval(checkExpiration, 1000);
      return () => clearInterval(interval);
    }

    getApprovedTemplates(dispatch).then(resp => {
      if (resp?.status === 200) { setAllTemplates(resp?.data?.data); }
      else { toast.error("Error occured...", { theme: "colored" }) }
    })
  }, [dispatch, sessionInfo]);


  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 1000);
    return () => clearTimeout(timeoutId);
  }, [messagesData]);


  useEffect(() => {
    if (sendTempModal) {
      const handleClickOutside = (event) => {
        setSendTempModal(false);
      };
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [sendTempModal]);


  useEffect((e) => {
    setSelectedAgent(sessionInfo?.assignedUser?.id);
    setSelectedStatus(sessionInfo?.sessionStatus);
  }, [sessionInfo]);

  const handleAgentChange = (e) => {
    setSelectedAgent(e.target.value);
    assingAgent(e);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    changeConvoStatus(e);
  };

  const handleClearMessage = () =>{
    document.getElementById('messageInput').value = ''
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendChatMessage();
      document.getElementById('messageInput').value = '';
    }
  };


  const handleHeaderTemplateChange = (e, componentType) => {
    const { value } = e.target;
    // Function to extract the parameter based on the new value and existing placeholders
    const extractParameter = (text) => {
        const regex = /{{(\d+)}}/g;
        const match = regex.exec(text);

        if (match) {
            // If a placeholder is found, return the word before it
            const beforePlaceholder = text.slice(0, match.index).trim();
            return beforePlaceholder ? beforePlaceholder.split(' ').pop() : ""; // Return the last word before the placeholder
        } else {
            // If no placeholder, return the last word in the text
            return text.trim().split(' ').pop();
        }
    };

    // Update the template data with the new text and adjust parameter/placeholders accordingly
    setTemplateData(prevData => {
        const updatedComponents = { ...prevData.template.components };
        if (componentType === 'header') {
            // Update the header parameter based on the text input
            const newParameter = extractParameter(value);
            updatedComponents.header = {
                ...updatedComponents.header,
                text: value,
                parameter: newParameter // Update parameter
            };
        } 
        return {
            ...prevData,
            template: {
                ...prevData.template,
                components: updatedComponents
            }
        };
    });
};

const handleHeaderMediaChange = (e) => {
  getTemplateHeaderMediaUrl(dispatch, {mediaFile: e.target.files[0]}).then((resp) => {
    if (resp?.data?.message === "Success") {
      setTemplateData(prevData => ({
        ...prevData,
        template: {
            ...prevData.template,
            components: {
                ...prevData.template.components,
                header: {
                    ...prevData.template.components.header,
                    mediaUrl: resp?.data?.data
                }
            }
        }
    }));
    }else{
      toast.error("Failed to uploud file!", { theme: "colored" });
    }
  });
  }


  const handleViewTemplate = (template) => {
    setViewTemplate(true)

    const extractPlaceholders = (text) => {
      const regex = /{{\d+}}/g;
      const matches = text.match(regex);
      return matches || []; // Return matches or empty array if no placeholders found
      };

      setBodyTemplateVariables(extractPlaceholders(template?.components.find(component => component.type === 'BODY')?.text))

      const headerComponent = template.components.find(component => component.type === 'HEADER');
      const bodyComponent = template.components.find(component => component.type === 'BODY');

      // Set up initial header and body placeholders
      let initialHeaderParameter = "";
      if (headerComponent.format === "TEXT") {
          const headerPlaceholders = extractPlaceholders(headerComponent.text);
          initialHeaderParameter = headerPlaceholders[0] || ""; // Set first placeholder as initial parameter
      }  
      const initialBodyPlaceholders = extractPlaceholders(bodyComponent.text);


    const updatedHeader  = template?.components.find(component => component.type === 'HEADER')?.format === "TEXT" ?(
      {
        type: template?.components.find(component => component.type === 'HEADER')?.format,
        text: template?.components.find(component => component.type === 'HEADER')?.text,
        parameter: initialHeaderParameter
    }
    ) : {
      type: template?.components.find(component => component.type === 'HEADER')?.format,
      mediaUrl: "",
  }
    setTemplateData({
      messageId: "6f11715c-fd83-4c20-bdc8-974e79624a92",
      to: contactDetails?.phoneNumber,
       template: {
        name: template.name,
        languageCode: "en",
        category: template.category,
        components: {
            header: updatedHeader,
            body:{
                text: template?.components.find(component => component.type === 'BODY')?.text,
                placeholders: initialBodyPlaceholders
            }
        }
    }  
    })
    console.log(template)
  }


  const handleVariableChange = (index, value) => {
    setBodyTemplateVariables((prev) => {
        const updatedValues = [...prev];
        updatedValues[index] = value; 

        setTemplateData(prevData => ({
          ...prevData,
          template: {
              ...prevData.template,
              components: {
                  ...prevData.template.components,
                  body: {
                      ...prevData.template.components.body,
                      placeholders: updatedValues
                  }
              }
          }
      }));
        return updatedValues;
    });

  
};
  

  const handleSendTemplate = () => {
    
    sendTemplate(dispatch, templateData).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data.error === true) {
          toast.error(resp?.data.message, { theme: "colored" })
        } else {
          handleCloseModal(dispatch)
          toast.success("Created successful!", { theme: "colored" })
          window.location.reload()
        }
      }
      else {toast.error("Error occured", { theme: "colored" }) }
    })
  }

  const handleCloseModal = () => {
    setViewTemplate()
    document.getElementById("form-modal").reset();
    setTemplateData();
    setViewTemplate(false)
  }
  
  
  return (
    <div className='inbox-chat-section'>
      <div style={{ height: '80vh' }}>
        <div className='actions-chat-section'>

          <div className='active-agent-actions'>
            <div className='session-timer-div'>
              <p className='agent-status-info'>Chat expires in</p>
              <SessionTimer sessionExpiration={sessionExpiration} />
            </div>
            <div className='active-agent-status'>
              <p className='agent-status-info'>Assign Agent</p>
              <select
                type='text'
                className='agent-actions-select-field'
                value={selectedAgent}
                onChange={handleAgentChange}
                disabled={sessionExpired}
              >
                <option id='option' value={sessionInfo?.assignedUser?.id} selected>
                  {sessionInfo?.assignedUser?.name}
                </option>
                {allAgents
                  ?.filter((e) => e.name !== sessionInfo?.assignedUser?.name)
                  .map((agentOption) => (
                    <option id='option' value={agentOption.id} key={agentOption.id}>
                      {agentOption?.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className='agent-ticket-status-section'>
            <p className='agent-status-info'>Chat Status</p>
            <select
              type='text'
              className='agent-actions-select-field'
              onChange={handleStatusChange}
              value={selectedStatus}
              disabled={sessionExpired}
              style={{ textTransform: 'capitalize', backgroundColor: 'white', overflowY: "scroll" }}
            >
             {chatStatusList.map(status => 
               <option id='option' value={status.value} key={status.title}>{status?.title} </option>)}
            </select>
          </div>
        </div>
        <div className='messages-section'>
          {messagesData.map((message, index) => (
            <div key={index} className={message.messageSource === 'OUTBOUND' ? 'outgoing-message-sec' : 'incoming-message-sec'}>
              <div className={message.messageSource === 'OUTBOUND' ? 'outgoing-message' : 'incoming-message'}>
                {message?.messageType === 'IMAGE' ? (
                  <img src={message?.mediaUrl} className='message-content image-content' alt='' />
                ) : message?.messageType === 'LIST' || 'INTERACTIVE' ? (
                  message?.text.split('\n').map((msg, idx) => (
                    <p className='message-content' key={idx}>
                      {msg}
                    </p>
                  ))
                ) : (
                  <p className='message-content'>{message?.text}</p>
                )}
                <p className='message-datetime'>{dateFormat(message?.createdAt, 'dd/mm/yyyy HH:MM')}</p>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className='chatbox-input'>
        {sessionExpired? 
        <div>
          <div style={{display: "flex", justifyContent:'space-between', alignItems: 'flex-end'}}>
             <p style={{fontSize: '20px', fontWeight: 'bold'}}>This chat is resolved and expired</p>

             <div className='send-template-section'>
              {sendTempModal &&
                <div className='list-tamplates-sec' >
                {/* <Input type='text' className="filter-template" placeholder="search template" />   */}
                  <Grid item xs={14} md={6} className='grid-list-tamplates'>
                        <List dense={true}>
                            {allTemplates?.data?.length > 0 ?
                                allTemplates?.data?.map((item, index) => (
                                    <ListItem key={index} className='grid-template-item'
                                    secondaryAction={<button className='start-convo-temp-btn'>Select</button>}
                                    onClick={() => handleViewTemplate(item)}>
                                        <ListItemText primary={item.name} secondary={
                                      <div style={{display: 'flex'}}>
                                       <p style={{marginRight: '12px', fontSize: "12px", fontWeight: '600'}}>Category: {item.category}</p>
                                       <p style={{fontSize: "12px",fontWeight: '600'}}>Type: {(item.components.find(item => item.type === "HEADER") || {}).format}</p>
                                      </div>
                                      }   />
                                    </ListItem>
                                )) :
                                <p id='not-found-info'>No templates found!</p>
                            }
                        </List>
                    </Grid>
                  </div>
              }
             

             <button className='select-temp-btn' onClick={()=> setSendTempModal(!sendTempModal)}>Send Template</button>
             </div>
             
          </div>
          <p style={{fontSize: '14px', fontWeight: '500'}}>Chats are marked as expired 24 hours after the last received customer message.</p>
        </div> :

          <div className='chatbox-input'>
          {selector?.user?.id === selectedAgent ? 
             
             <div className='chatbox-input'>
             <input type='text' id="messageInput" name='message' placeholder='Type a message here' 
             onChange={handleChatMessageChange} onKeyPress={handleKeyPress} onKeyDown={keyDownCreateNewLine}
             disabled={sessionExpired}
             />
             {chatMessage?.message  && (
               <button type='submit' className='send-message-button' onClick={() => {sendChatMessage(); handleClearMessage()}}>
                 Send
               </button>
             )}
             </div>
             :<div>
                <p style={{fontSize: '20px', fontWeight: 'bold'}}>You are not assigned to this chat!</p>
                <p style={{fontSize: '14px', fontWeight: '500'}}>Assign yourself to get started</p>
              </div>
         }
          </div>
      }
      </div>



      {/* edit template variables before sending */}
      <div className={viewTemplate ? 'template-create-modal-active' : 'template-create-modal-inactive'} >
        <div className='create-whatsapp-template-modal'>
            <form id="form-modal" className='template-view-modal-content create-template-form'>
              <p className='broadcast-modal-title'>Update Template Variables
                <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal(dispatch)} /></p>
              <div className="template-broadcast-dialog">
                
                 {/* add template meta data section */}
                <div className='template-dialog-inputs'>
                  <Input id="template-input" label="Template Name" name='name' value={templateData?.template?.name} disabled='true'/>
                  <Input label="Category" value={templateData?.template?.category} disabled='true'/>
                  <Input id="template-input" label="Language" type='text' value="English" disabled='true'/>
                </div>
                
                 {/* add template content section */}
                <div className='template-dialog-inputs-title'>
                  <p style={{"fontSize": "14px", "color": "rgb(38, 39, 39)", "fontWeight": "600"}}>Header</p>
                    {
                    templateData?.template?.components.header.type === "TEXT"? (
                      <div>
                      <Input id="template-input" name='text' type='text' handleChange={(e) => handleHeaderTemplateChange(e, 'header')}
                         value={templateData?.template?.components.header.text}/>
                      </div>
                        )
                    
                    :templateData?.template?.components?.header?.type === "IMAGE"?(
                     <FileInput label="Choose PNG or JPG file" name='image' accept="image/*" selectedFile={templateData?.component?.header?.text}
                     handleChange={(e)=>handleHeaderMediaChange(e)}/>)
                    
                    :templateData?.template?.components?.header?.type === "VIDEO"?(
                     <FileInput label="Choose MP4 file" name='video' accept="video/mp4, video/webm" selectedFile={templateData?.component?.header?.text} 
                     handleChange={(e)=>handleHeaderMediaChange(e)}/>)

                    :templateData?.template?.components?.header?.type === "DOCUMENT"?(
                     <FileInput label="Choose PDF file" name='document' type='file' accept=".pdf,.doc,.docx,.xlsx" selectedFile={templateData?.component?.header?.text} 
                     handleChange={(e)=>handleHeaderMediaChange(e)}/>)

                    : null
                    }      
                </div>
                  
                  <div>
                  <TextArea label="Body" name='message' type='text' defaultValue={templateData?.template?.components.body.text} readOnly/>   
                    <div className='body-variable-inputs'>
                      {bodyTemplateVariables.map((value, index) => (   
                        <Input key={`input-${index}`} id={`placeholder-${index}`} label={`Variable ${index+1}`} type='text'
                          handleChange={(e) => handleVariableChange(index, e.target.value)} value={value}/>     
                          ))}
                    </div>
                    
                    </div>
          
                <div className='template-dialog-inputs-title'>
                  <h5>Footer</h5>
                  <Input id="template-input" disabled={true}
                  value={templateData?.template?.components?.footer?.text ? templateData?.template?.components?.footer?.text : "N/A"} />
                </div>
                 
                 {/* add template buttons section */}
                <div className='template-dialog-inputs-title'>
                    <h5>Buttons</h5>
                    {templateData?.template?.buttons?.map((btn, index)=>(
                      <div className='template-buttons-list' key={index}>
                        <div className='added-temp-btns'>
                         <Input id="template-button-text" type='text' value={btn.type}/>  
                          <Input id="template-button-text" type='text' value={btn.text} name="text"/>  
                          {btn.type === 'PHONE_NUMBER' ? (
                            <Input id="template-button-text" type='number' value={btn.phoneNumber} name="phoneNumber"/>  
                            ):
                          btn.type === 'URL'?(
                          <Input id="template-button-text" type='text' placeholder="Url" value={btn.url} name="url"/>
                            ): ''
                          }
                        </div>
                        </div>
                      ))}
                </div> 
              </div>
              <div className='dialog-footer'>
              <SubmitButton pending={action.pending} handleOnSubmit={handleSendTemplate} />  
              </div>
            </form>
          </div>

          </div>

      <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
    </div>
  );
};

export default ChatSection;
