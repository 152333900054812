import React, { useState, useEffect } from 'react'
import './profile.css'

import Input from '../ReUsedComponents/inputField/Input'
import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';

import { updateUserPassword } from '../../apis/ProfileAPI';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {

    const dispatch = useDispatch()
    // const userInfo = useSelector((state) => JSON.parse(state.auth.userInfo))
    const action = useSelector((state) => state.action)
    const selector = useSelector((state) => state.session)

    const [user, setUser] = useState();
    const [businessDetails, setBusinessDetails] = useState()
    const [passwordDetails, setPasswordDetails] = useState()
    const [formErrors, setFormErrors] = useState()
    const [businessProfileForm, setBusinessProfileForm] = useState(true);
    const [userProfileForm, setUserProfileForm] = useState(false);

    const handleOnUserChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    const handleOnBusinessChange = (e) => {
        setBusinessDetails({ ...businessDetails, [e.target.name]: e.target.value })
    }
    const handleOnPasswordChange = (e) => {
        setPasswordDetails({ ...passwordDetails, [e.target.name]: e.target.value })
    }
    //////////GETTING ALL USERS/ROLES//////////
    useEffect(() => {
        if(selector?.user?.roleView?.id === 2 || selector?.user?.roleView?.id === 3){
            setBusinessProfileForm(false)
            setUserProfileForm(true)
        }
            
        // getUserDetails(dispatch).then(resp => {
        //     if (resp?.status === 200) { setUser(resp?.data?.data) }
        //     else { toast.error("Unabled to fetch roles!", { theme: "colored" }) }
        // })
    }, [dispatch, selector?.user?.roleView?.id])

    //////////VALIDATING FORM//////////
    const validate = () => {
        let errors = {}
        if (!user?.firstName) {
            errors['firstName'] = 'Field is required!'
        }
        if (!user?.lastName) {
            errors['lastName'] = 'Field is required!'
        }
        if (!user?.username) {
            errors['username'] = 'Field is required!'
        }
        if (!user?.client?.name) {
            errors['company'] = 'Field is required!'
        }

        setFormErrors(errors)
        return errors
    };

    const validatePasswordDetails = () => {
        let errors = {}
        if (!passwordDetails?.currentPassword) {
            errors['currentPassword'] = 'Field is required!'
        }
        if (!passwordDetails?.newPassword) {
            errors['newPassword'] = 'Field is required!'
        }
        if (!passwordDetails?.confirmNewPassword) {
            errors['confirmNewPassword'] = 'Field is required!'
        }
        if (passwordDetails?.confirmNewPassword !== passwordDetails?.newPassword) {
            errors['confirmNewPassword'] = `Passwords don't match!`
        }
        setFormErrors(errors)
        return errors
    };

    //////////UPDATE USER//////////
    const handleUpdateUser = () => {
         if (Object.keys(validate()).length === 0) {
        //     updateUser(user, dispatch).then(resp => {
        //         if (resp?.status === 200) {
        //             toast.success("Updated successful!", { theme: "colored" })
        //             window.location.reload()
        //         }
        //         else { 
        //             toast.error("Unable to update!", { theme: "colored" }) 
        //         }
        //     })
        }
    }


    const handleUpdateUserPassword = () => {
        if (Object.keys(validatePasswordDetails()).length === 0) {
            updateUserPassword(dispatch, passwordDetails).then(resp => {
                if (resp?.status === 200) {
                    toast.success("Password updated successfully!", { theme: "colored" });
                    window.location.reload()
                }else {
                    toast.error("An error occurred. Please try again.", { theme: "colored" });
                }
            }).catch(err => {
                toast.error("Current password supplied is wrong!", { theme: "colored" });
            });
        }
    };


    return (

        <div className='user-profile-page'>
           
            <div className='page-header-section'>
               <p className='profile-title'>Manage Your Profile</p>
            </div>

            <div className='profile-btns-header-section'>
            {selector?.user?.roleView?.id === 1? 
              <button className='profile-form-btn' onClick={()=> { setBusinessProfileForm(true); setUserProfileForm(false)}}>Business Profile</button> : ''}
              <button className='profile-form-btn' onClick={()=> { setBusinessProfileForm(false); setUserProfileForm(true)}}>User Profile</button>
            </div>

            <div className='main-profile-section'>

                {/* business proflie update section */}
                {!businessProfileForm? "" :
                <div className='user-info-section'>
                    <div className='page-header-section' style={{display:'flex', justifyContent: 'space-between'}}>
                        <p className='profile-title'>Business Profile</p>
                    </div>
                    <div className='info-fields-section'>
                       <Input label="Phone Number" name='phoneNumber' type='text' defaultValue={businessDetails?.firstName} handleChange={(e) => handleOnBusinessChange(e)} errorMessage={formErrors?.firstName}/>
                       <Input label="About" name='about' type='text' defaultValue={businessDetails?.firstName} handleChange={(e) => handleOnBusinessChange(e)} errorMessage={formErrors?.firstName}/>
                       <Input label="Business Address" name='businessAddress' type='text' defaultValue={businessDetails?.firstName} handleChange={(e) => handleOnBusinessChange(e)} errorMessage={formErrors?.firstName}/>
                       <Input label="Business Description" name='businessDescription' type='text' defaultValue={businessDetails?.firstName} handleChange={(e) => handleOnBusinessChange(e)} errorMessage={formErrors?.firstName}/>
                       <Input label="Email for business contact" name='businessEmail' type='text' defaultValue={businessDetails?.firstName} handleChange={(e) => handleOnBusinessChange(e)} errorMessage={formErrors?.firstName}/>
                       <Input label="Business Industry" name='businessIndustry' type='text' defaultValue={businessDetails?.firstName} handleChange={(e) => handleOnBusinessChange(e)} errorMessage={formErrors?.firstName}/>
                       <Input label="Business Website" name='businessWebsite' type='text' defaultValue={businessDetails?.firstName} handleChange={(e) => handleOnBusinessChange(e)} errorMessage={formErrors?.firstName}/>
                    </div>

                    <div className='dialog-footer'>
                            <SubmitButton pending={action.pending} handleOnSubmit={handleOnBusinessChange} />
                        </div>
                </div>}


                {/* user proflie update section */}
                {!userProfileForm? "" :
                <div>
                <div className='user-info-section'>
                  <div className='user-info-section-div'>
                    <div className='page-header-section' style={{display:'flex', justifyContent: 'space-between'}}>
                        <p className='profile-title'>User Profile</p>
                    </div>
                    <div className='info-fields-section'>
                    <Input label="First Name" name='firstName' type='text' defaultValue={user?.firstName} handleChange={(e) => handleOnUserChange(e)} errorMessage={formErrors?.firstName}/>
                    <Input label="Last Name" name='firstName' type='text' defaultValue={user?.lastName} handleChange={(e) => handleOnUserChange(e)} errorMessage={formErrors?.firstName}/>
                    <Input label="Email" name='firstName' type='text' defaultValue={user?.username} handleChange={(e) => handleOnUserChange(e)} errorMessage={formErrors?.firstName}/>
                    <Input label="Phone Number" name='firstName' type='text' defaultValue={user?.phoneNumber} handleChange={(e) => handleOnUserChange(e)} errorMessage={formErrors?.firstName}/>
                    </div>
                    <div className='dialog-footer'>
                            <SubmitButton pending={action.pending} handleOnSubmit={handleUpdateUser} />
                        </div>
                    </div>
                </div>
                
                
                <form id="form-modal" className='user-info-section'>
                  <div className='user-info-section-div'>
                    <div className='page-header-section' style={{display:'flex', justifyContent: 'space-between'}}>
                        <p className='profile-title'>Update Password</p>
                    </div>
                    <div className='info-fields-section'>
                    <Input label="Current Password*" name='currentPassword' type='password' handleChange={(e) => handleOnPasswordChange(e)} errorMessage={formErrors?.currentPassword}/>
                    <Input label="New Password*" name='newPassword' type='password' handleChange={(e) => handleOnPasswordChange(e)} errorMessage={formErrors?.newPassword}/>
                    <Input label="Confirm Password*" name='confirmNewPassword' type='password' handleChange={(e) => handleOnPasswordChange(e)} errorMessage={formErrors?.confirmNewPassword}/>
                    </div>
                    <div className='dialog-footer'>
                            <SubmitButton pending={action.pending} handleOnSubmit={handleUpdateUserPassword} />
                        </div>
                   </div>
                </form>

                </div>
                }

            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>

    );
}

export default Profile