import React, { useState, useEffect } from 'react'
import "./broadcast.css"
import { DataGrid} from '@mui/x-data-grid';

import Input from '../ReUsedComponents/inputField/Input'
import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';
import Loading from '../ReUsedComponents/loadingScreen/Loading';
import SelectField from '../ReUsedComponents/selectField/SelectField'
import FileInput from '../ReUsedComponents/inputField/FileInput'

import { getAllBroadcast, sendBroadcast } from '../../apis/BroadcastAPI';
import { getAllContacts } from '../../apis/ContactsAPI';
import { getApprovedTemplates } from '../../apis/TemplatesAPI';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import SearchSelector from '../ReUsedComponents/searchSelector/SearchSelector';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Broadcast = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const [broadcastModalState, setBroadcastModalState] = useState(false);
   
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [pageSizeContacts, setPageSizeContacts] = useState(10);
    const [pageNoContacts, setPageNoContacts] = useState(1);

    const [formErrors, setFormErrors] = useState()

    const [allBroadcast, setAllBroadcast] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [contactGroups, setContactGroups] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    const [name, setName] = useState();
    const [templateName, setTemplateName] = useState();
    const [mediaFile, setMediaFile] = useState();
    const [contactsFile, setContactsFile] = useState();
    const [mediaFileError, setMediaFileError] = useState()
    const [selectedContacts, setSelectedContacts] = useState()

    useEffect(() => {
        getAllBroadcast(dispatch, pageNo, pageSize).then(resp => {
            if (resp?.status === 200) { setAllBroadcast(resp?.data);}
            else { toast.error("Error occured...", { theme: "colored" }) }
          })
        getApprovedTemplates(dispatch).then(resp => {
          if (resp?.status === 200) { setAllTemplates(resp?.data?.data); }
          else { toast.error("Error occured...", { theme: "colored" }) }
        })
        getAllContacts(dispatch, pageNoContacts, pageSizeContacts).then(resp => {
          if (resp?.status === 200) { 
            setAllContacts(resp?.data); 
            const uniqueGroups = [...new Set(resp?.data?.data.map(contact => contact.group))];
            setContactGroups(uniqueGroups)
          }
          else { toast.error("Error occured...", { theme: "colored" }) }
        })
    }, [dispatch, pageSize, pageNo, pageNoContacts, pageSizeContacts])


    const columns = [
        { field: 'name', headerName: 'Broadcast Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false },
        { field: 'contacts', headerName: 'Contacts', flex: 1, minWidth: 100, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => <span>{param.row.result? param.row.result.sent : '-'}</span>},
        { field: 'delivered', headerName: 'Delivered', flex: 1, minWidth: 100, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => <span>{param.row.result? param.row.result.sent : '-'}</span>},
        { field: 'sent', headerName: 'Sent', flex: 1, minWidth: 100, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => <span>{param.row.result? param.row.result.sent : '-'}</span>},
        { field: 'pending', headerName: 'Pending', flex: 1, minWidth: 100, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => <span>{param.row.result? param.row.result.pending : '-'}</span>},
        { field: 'failed', headerName: 'Failed', flex: 1, minWidth: 100, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => <span>{param.row.result? param.row.result.failed : '-'}</span>},
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 100, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => <span id={`status-${param.row.status}`}>{param.row.status}</span>},
        // { field: 'updatedAt', headerName: 'Created By', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
        // renderCell: (param) => <span>{dateFormat(param.row.updatedAt, "dd/mm/yyyy HH:MM")}</span> },
        { field: 'createdAt', headerName: 'Created At', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
        renderCell: (param) => <span>{dateFormat(param.row.createdAt, "dd/mm/yyyy HH:MM")}</span>},
        // { field: 'actions',
        //     headerName: 'Actions',
        //     width: 150,
        //     headerClassName: 'custom-table-header',
        //     sortable: false,
        //     renderCell: (param) => (
        //         <div className='contacts-table-actions'>
        //           <i class="pi pi-eye" id="view-action-icon" onClick={() => { setContact({ id: param.row?.id, name: param?.row?.name, phoneNumber: param?.row?.phoneNumber});}}/>
        //         </div>
        //     )
        // },
    ];

    const contactsColumns = [
      { field: 'name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{param.row.name? param.row.name : "--"}</span>},
      { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'group', headerName: 'Group', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false,
      renderCell: (param) => <span>{param.row.group? param.row.group : "--"}</span>},
  ];

    const validate = () => {
      let errors = {}
      if (!name) {
        errors['name'] = 'Name is required!'
      }
      if (!templateName) {
        errors['template'] = 'Template message is required!'
      }
      if (!contactsFile && !selectedContacts) {
        errors['contactsFile'] = 'Please upload contacts file or select from table!'
      }
    
      setFormErrors(errors)
      return errors
    };

    const handleOnSelect = (option) => {
      setTemplateName(option.name); 
      setSelectedTemplate(option)
    };

    const handleSendBroadcast = () => {
      if (Object.keys(validate()).length === 0) {
        sendBroadcast(dispatch, {name: name, templateName: templateName, contactsFile: contactsFile, selectedContacts: selectedContacts, mediaUrl: mediaFile}).then(res =>{
          if (res?.status === 200) {
            handleCloseModal(dispatch)
            toast.success("Sent successful!", { theme: "colored" })
            window.location.reload()
        }else{
          toast.error(res?.response?.data.message, { theme: "colored" })
          setMediaFileError(res?.response?.data.message)
        }
        })
       }
    };

    const handleCloseModal = () => {
      setFormErrors([])
      document.getElementById("form-modal").reset();
      setBroadcastModalState(false)
      setName();
      setTemplateName();
      setContactsFile();
      setSelectedContacts()
    }


    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title' style={{marginLeft: '11px'}}>Broadcasts</p>
                </div>
                <div className='table-toolbar'>
                  <input type='text' className="search-table-input" placeholder='Search table...' />
                  <button type='button' className='create-btn' onClick={() => setBroadcastModalState(true)}>Send Broadcast</button>
                </div>
                
            </div>
            <div>


        <div className='table-section'> 
           {allBroadcast?.length === 0? <div className='table-loading'><Loading /></div> : 
            <DataGrid rowHeight={40} rows={allBroadcast?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage + 1)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allBroadcast?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',height: '500px'}} 
                    />}
            </div>
          
            </div>


  {/* /////////////////////SEND BROADCAST //////////// */}
  <div className={broadcastModalState ? 'broadcast-create-modal-active' : 'broadcast-create-modal-inactive'} >
        <form id="form-modal" className='broadcast-view-modal-content broadcast-form'>
          <p className='broadcast-modal-title'> Send Broadcast
            <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal(dispatch)} /></p>
          
            <div className='broadcast-template-info'>
              <div className='broadcast-info'>
                <Input label="Name *" name='name' type='text' handleChange={(e) => setName(e.target.value)} errorMessage={formErrors?.name}/>
                <SearchSelector options={allTemplates.data} label="Template*" onSelect={handleOnSelect} name="templateName" 
                  value={templateName} errorMessage={formErrors?.template}/>
            </div>

            {selectedTemplate && selectedTemplate?.components.find(component => component.type === "HEADER").format !== "TEXT" &&(
                  <div style={{marginBottom: '10px'}}>
                  <p style={{fontSize: '14px', fontWeight: '600'}}>Media File</p>
                  <span style={{fontSize: '12px', fontWeight: '600'}}>*Required only when template type is MEDIA</span>
                  
                  <FileInput label="Upload media file" name='image' handleChange={(e)=>setMediaFile(e.target.files[0])}
                          accept="image/*" uploading={false} selectedFile={mediaFile} errorMessage={mediaFileError}/>
                </div>
            )}
             
            <div className='broadcast-contacts'> 
             <p style={{"fontSize":"14px", "fontWeight":"600"}}>Contacts *</p>

            <div className='import-sec'>
              <a className="template-link" href={process.env.PUBLIC_URL + "/samplebroadcastfile.csv"}>
              <i className="pi pi-file" style={{ 'fontSize': '1em' }} />Download contacts file template</a>

              <label for="import-btn" id='import-label'>
                <i className="pi pi-download" style={{ 'fontSize': '1.5em', 'fontWeight': '200' }} /><br />
                {contactsFile ? <strong>{contactsFile?.name}</strong> : <span>Upload contacts</span>}
              </label>
              <input type="file" name="contactsFile" hidden accept=".xlsx, .csv" id="import-btn"
                onChange={(e) => setContactsFile(e.target.files[0])}/>
                <span id="error">{formErrors?.contactsFile}</span>
            </div>

            <div className='broadcast-contacts-table'> 
              <div className='broadcast-info'>
              <Input type='text' handleChange={(e) => setName(e.target.value)} placeholder="Search contact"/>
              <SelectField placeholder="Filter by group" selects={contactGroups} handleChange={(e) => setName(e.target.value)} />
            </div>

            {allContacts?.length === 0? <Loading /> : 
              <DataGrid rowHeight={35} rows={allContacts?.data} columns={contactsColumns} disableColumnMenu
                      className="datagrid-root" getRowClassName="dashboard-transaction-rows"
                      checkboxSelection
                      pageSize={pageSizeContacts}
                      onPageSizeChange={(newPageSize) =>setPageSizeContacts(newPageSize)}
                      onPageChange={(newPage) => setPageNoContacts(newPage + 1)}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      rowCount={allContacts?.data?.totalElements}
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRows = allContacts?.data.filter((row) =>
                          selectedIDs.has(row.id),
                        );
                        const selectedContacts = selectedRows.map(row => ({
                          phoneNumber: row.phoneNumber,
                          name: row.name
                            }));
                        setSelectedContacts(selectedContacts);
                      }}
                      paginationMode="server"
                      pagination
                      componentsProps={{panel: { className: "custom-colum-panel",},}}
                      
                      sx={{border: 'none',height: '320px'}} 
                      />}
              </div>
          </div>

          </div>
          <div className='dialog-footer'>
          <SubmitButton pending={action.pending} handleOnSubmit={handleSendBroadcast} />
          </div>
        </form>
      </div>

      <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    )
}

export default Broadcast