import React, { useState, useEffect, useCallback} from 'react'
import './teaminbox.css'

import { getAllChats, getContactMessages, sendChatMessage, updateConvostatus, 
  updateConvoAgent } from '../../apis/TeamInboxAPI'; 
import { getAllUsers } from '../../apis/UsersAPI'

import RightSidebar from './rightSidebar/RightSidebar'
import ChatSection from './chatSection/ChatSection'
import InboxContacts from './inboxContacts/InboxContacts'

import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useWebSocket from '../CustomWebSocket';


const TeamInbox = ({ idName }) => {

  const dispatch = useDispatch()
  const selector = useSelector((state) => state.session)

  // const [pageSize, setPageSize] = useState(25);
  // const [pageNo, setPageNo] = useState(1);

  const [allChats, setAllChats] = useState([]);
  const [allAgents, setAllAgents] = useState()
  const [contactMessages, setContactMessages] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [conversationStatus, setConversationStatus] = useState(null);
  const [chatMessage, setChatMessage] = useState('')

  const [sessionExpiration, setSessionExpiration] = useState(false);

  const handleUpdateConvoStatus = (e) => {
    updateConvostatus(dispatch, conversationStatus?.sessionId, { status: e.target.value }).then(resp => {
      if (resp?.status === 200) { 
        // if(e.target.value === "SOLVED"){
        //   updateConvoAgent(dispatch, conversationStatus?.sessionId, { userId: selector?.user?.id }).then(resp => {
        //     if (resp?.status === 200) { 
        //       return getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
        //         if (resp?.status === 200) { 
        //           setConversationStatus(resp?.data?.sessionInfo)
        //           setContactMessages(resp.data.data.reverse()); 
        //           getAllChats(dispatch, 1, 100).then(resp1 => {
        //             setAllChats(resp1.data.data);
        //           });
        //         }
        //         else { console.log("Error occured...") }
        //       }) 
        //   }
        //     else { toast.error("Error occured!", { theme: 'colored' }); }
        //    })
        // }
          return getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
            if (resp?.status === 200) { 
              setConversationStatus(resp?.data?.sessionInfo)
              setContactMessages(resp.data.data.reverse()); 
              getAllChats(dispatch, 1, 100).then(resp1 => {
                setAllChats(resp1.data.data);
              });
              toast.success("Status updated!", { theme: 'colored' });
            }
            else { toast.error("Error occured...", { theme: "colored" }) }
          }) 
       
       
      } else { 
        toast.error("Error occurred!", { theme: 'colored' });
      }
    });
  }

  const fetchAllChats = useCallback(() => {
    getAllChats(dispatch, 1, 100).then(resp1 => {
      if (resp1?.status === 200) {
        const promises = resp1.data.data?.chats?.map(contact => {
          if (contact?.sessionInfo?.expiresAt) {
            const expirationTime = new Date(contact.sessionInfo.expiresAt).getTime();
            const checkExpiration = () => {
              const now = new Date().getTime();
              if (now > expirationTime && contact?.sessionInfo?.sessionStatus !== "EXPIRED") {
                return updateConvostatus(dispatch, contact?.sessionInfo?.sessionId, { status: "EXPIRED" });
              } 
              return Promise.resolve(); // No update needed
            };

            return checkExpiration();
          } else {
            return Promise.resolve(); // No expiration check needed
          }
        });

        Promise.all(promises).then(() => {
          getAllChats(dispatch, 1, 100).then(resp1 => {
            setAllChats(resp1.data.data);
          });
        }).catch(error => {
          console.error("Error updating statuses: ", error);
        });
      } else {
        toast.error("Error occured...", { theme: "colored" });
      }
    });
    if (selectedItem) {
      getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
        if (resp?.status === 200) { 
          setConversationStatus(resp?.data?.sessionInfo)
          setContactMessages(resp.data.data.reverse()); 
          if(resp?.data?.sessionInfo?.sessionStatus === 'EXPIRED'){
            setSessionExpiration(true)
          }else{
            setSessionExpiration(false)
          }
        }
        else {  toast.error("Error occured...", { theme: "colored" }) }
      }) 
    }
  }, [dispatch, selectedItem]);

  useWebSocket(fetchAllChats);

  useEffect(() => { 
    getAllUsers(dispatch, 1, 100).then(resp => {
      if (resp?.status === 200) { setAllAgents(resp?.data?.data) }
      else {  toast.error("Error occured...", { theme: "colored" }) }
    })
   
    getAllChats(dispatch, 1, 100).then(resp1 => {
      if (resp1?.status === 200) {
        const promises = resp1.data.data?.chats?.map(contact => {
          if (contact?.sessionInfo?.expiresAt) {
            const expirationTime = new Date(contact.sessionInfo.expiresAt).getTime();
            const checkExpiration = () => {
              const now = new Date().getTime();
              if (now > expirationTime && contact?.sessionInfo?.sessionStatus !== "EXPIRED") {
                return updateConvostatus(dispatch, contact?.sessionInfo?.sessionId, { status: "EXPIRED" });
              }
              else {
                // Return a resolved promise if no update is needed
                return Promise.resolve();
              }
            };
    
            return checkExpiration();
          } else {
            // Return a resolved promise if no expiration check is needed
            return Promise.resolve();
          }
        });
    
        Promise.all(promises).then(() => {
          getAllChats(dispatch, 1, 100).then(resp1 => {
            setAllChats(resp1.data.data);
          });
          
        }).catch(error => {
          console.error("Error updating statuses: ", error);
        });
      } else {
        toast.error("Error occured...", { theme: "colored" })
      }
    });
    }, [dispatch, selector?.user?.id, conversationStatus])

  
  const getContactChats = (chat) =>{
    setSelectedItem(chat);
    getContactMessages(chat?.id, dispatch, 1, 100).then(resp => {
      if (resp?.status === 200) { 
        setConversationStatus(resp?.data?.sessionInfo)
        setContactMessages(resp.data.data.reverse()); 
        if(resp?.data?.sessionInfo?.sessionStatus === 'EXPIRED'){
          setSessionExpiration(true)
        }else{
          setSessionExpiration(false)
        }
      }
      else {  toast.error("Error occured...", { theme: "colored" }) }
    }) 
  }

  const sendConversationMessage = () =>{
    sendChatMessage(dispatch, chatMessage).then(resp => {
      if (resp?.status === 200) {
        setChatMessage("")
        if(conversationStatus?.sessionStatus === "OPEN"){
          updateConvostatus(dispatch, conversationStatus?.sessionId, { status: "PENDING" }).then(resp =>{
            if (resp?.status === 200) {
              return getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
                if (resp?.status === 200) { 
                  setConversationStatus(resp?.data?.sessionInfo)
                  setContactMessages(resp.data.data.reverse());  
                  getAllChats(dispatch, 1, 100).then(resp1 => {
                    setAllChats(resp1.data.data);
                  });
                }
                else {  toast.error("Error occured...", { theme: "colored" }) }
              })
             }
          })
          
        }else{
          return getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
            if (resp?.status === 200) { 
              setConversationStatus(resp?.data?.sessionInfo)
              setContactMessages(resp.data.data.reverse());  
              getAllChats(dispatch, 1, 100).then(resp1 => {
                setAllChats(resp1.data.data);
              });
            }
            else {  toast.error("Error occured...", { theme: "colored" }) }
          }) 

        }
       }
      else { toast.error("Error occured...", { theme: "colored" }) }
    })  
  }

  const handleAssignAgent = (e) =>{
    updateConvoAgent(dispatch, conversationStatus?.sessionId, { userId: e.target.value }).then(resp => {
      if (resp?.status === 200) { 
        toast.success("Agent assigned!", { theme: 'colored' });
        return getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
          if (resp?.status === 200) { 
            if(conversationStatus?.sessionStatus === "OPEN"){
              updateConvostatus(dispatch, conversationStatus?.sessionId, { status: "PENDING" }).then(resp =>{
                if (resp?.status === 200) {
                  return getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
                    if (resp?.status === 200) { 
                      setConversationStatus(resp?.data?.sessionInfo)
                      setContactMessages(resp.data.data.reverse());  
                      getAllChats(dispatch, 1, 100).then(resp1 => {
                        setAllChats(resp1.data.data);
                      });
                    }
                    else {  toast.error("Error occured...", { theme: "colored" }) }
                  })
                 }
              })
              
            }else{
              return getContactMessages(selectedItem?.id, dispatch, 1, 100).then(resp => {
                if (resp?.status === 200) { 
                  setConversationStatus(resp?.data?.sessionInfo)
                  setContactMessages(resp.data.data.reverse());  
                  getAllChats(dispatch, 1, 100).then(resp1 => {
                    setAllChats(resp1.data.data);
                  });
                }
                else {  toast.error("Error occured...", { theme: "colored" }) }
              }) 
            }
            // setConversationStatus(resp?.data?.sessionInfo)
            // setContactMessages(resp.data.data.reverse()); 
            // getAllChats(dispatch, 1, 100).then(resp1 => {
            //   setAllChats(resp1.data.data);
            // });
          }
          else {  toast.error("Error occured...", { theme: "colored" }) }
        }) 
    }
      else { toast.error("Error occured!", { theme: 'colored' }); }
     })
  }

  const handleChatMessageChange = (e) => {
    setChatMessage( {to: selectedItem?.phoneNumber, message: e.target.value})
  };

  const handleKeyDownCreateNewLine = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setChatMessage( {to: selectedItem?.phoneNumber, message: event.target.value+ '\n'})
    }
  };

  const handleEmptyChat = () =>{
    return <div style={{margin: 'auto', marginTop: '50px', textAlign:'center'}}>
           <img src={process.env.PUBLIC_URL + "/images/conversation.png"} alt="not-selected-chat" style={{width: '40%'}} /> 
           <p style={{fontSize: '35px', fontWeight: 'bold'}}>No conversation selected!!</p>
           <p style={{fontSize: '16px', fontWeight: '400'}}>Select conversation to get started.</p>
    </div>
  }

  return (
    <div className='team-inbox-section'>
        <InboxContacts contactChats={allChats?.chats} getMessages={getContactChats} totalChats={allChats?.totalChats} 
                unreadChats={allChats?.totalUnread} styleId="scroll-contacts" sessionInfo={contactMessages}/>
          {selectedItem === null ? handleEmptyChat() :
          <div style={{display: 'flex', width: '78%'}}>
            <ChatSection allAgents={allAgents} messagesData={contactMessages} handleChatMessageChange={(e)=>{handleChatMessageChange(e)}} 
                    sendChatMessage={sendConversationMessage} chatMessage={chatMessage} changeConvoStatus={handleUpdateConvoStatus} 
                    assingAgent={handleAssignAgent} sessionInfo={conversationStatus} sessionExpired={sessionExpiration}
                    keyDownCreateNewLine={handleKeyDownCreateNewLine} contactDetails={selectedItem}/>
            <RightSidebar contactDetails={selectedItem}/>
        </div> }

        <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
   
    </div>
  )

}

export default TeamInbox