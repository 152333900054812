import React, { useState, useEffect } from 'react'
import "./usermanagement.css"
import { DataGrid} from '@mui/x-data-grid';
import dateFormat from 'dateformat';
// import { styled } from '@mui/material/styles';
// import Tooltip from '@mui/material/Tooltip';

import Input from '../ReUsedComponents/inputField/Input'
import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';
import Loading from '../ReUsedComponents/loadingScreen/Loading';
import RoleSelectField from '../ReUsedComponents/selectField/RoleSelectField'

import { getAllUsers, createUser, updateUser, suspendUser, deleteUser } from '../../apis/UsersAPI';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const UserManagement = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const [createModalState, setCreateModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
  
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(25);
   
    const [allUsers, setAllUsers] = useState([])
    const [user, setUser] = useState({ name: '', username: '', roleId: ''});
    const [formErrors, setFormErrors] = useState()

  // Getting all users
  useEffect(() => {
    getAllUsers(dispatch, pageNo, pageSize).then(resp => {
      if (resp?.status === 200) { setAllUsers(resp?.data) }
      else { toast.error("unable to fetch users!", { theme: "colored" }) }
    })
  }, [dispatch, pageNo, pageSize])


    const columns = [
        {field: 'name', headerName: 'Name', width: 150,flex:1, minWidth: 180,headerClassName: 'custom-table-header',sortable: false,},
        {field: 'username', headerName: 'Username', width: 200,flex:1, minWidth: 130, headerClassName: 'custom-table-header first-header',sortable: false,},
        {field: 'roleId', headerName: 'Role', width: 120,flex:1, minWidth: 150, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (param) => <span>{param.row?.role?.name}</span>},
        {field: 'loginStatus', headerName: 'Online Status', width: 100, flex:1, minWidth: 180, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => <span id={`status-${props?.row?.loginStatus}`}>{props?.row?.loginStatus}</span>},
        { field: 'createdAt', headerName: 'Created At', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
        renderCell: (param) => <span>{dateFormat(param.row.createdAt, "dd/mm/yyyy HH:MM")}</span>},
        { field: 'updatedAt', headerName: ' Last Logged In', flex: 1, minWidth: 180, headerClassName: 'custom-table-header', sortable: false,
        renderCell: (param) => <span>{dateFormat(param.row.updatedAt, "dd/mm/yyyy HH:MM")}</span> },
        {field: 'actions', headerName: 'Actions', width: 150, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (params) => (
              <div className='users-table-actions'>
                <i class="pi pi-user-edit" id="user-edit-action-icon" onClick={() => {setEditModalState(true); setUser({id: params?.row?.id ,name: params?.row?.name, roleId: params?.row?.role?.id, username: params?.row?.username}) }} />
                {params?.row?.userStatus === "DEACTIVATED"? 
                <span class="material-symbols-outlined" id="suspend-user-action-icon">person_check</span>:
                <span class="material-symbols-outlined" id="suspend-user-action-icon" onClick={() => handleSuspendUser(params?.row?.id, {name: params?.row?.name, roleId: params?.row?.role?.id, username: params?.row?.username})}>person_cancel</span>
              }
                <i class="pi pi-trash" id="delete-action-icon" onClick={() => handleDeleteUser(params?.row?.id, {name: params?.row?.name, roleId: params?.row?.role?.id, username: params?.row?.username})} />
              </div>
          )
      },
    ];


      const handleOnChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
      }
    
      const validate = () => {
        let errors = {}
        if (!user?.name) {
          errors['name'] = 'Name is required!'
        }
        if (!user?.username) {
          errors['username'] = 'Email is required!'
        }
        if (!user?.roleId) {
          errors['roleId'] = 'Role is required!'
        }
        setFormErrors(errors)
        return errors
      };
    
      
      const handleCloseModal = () => {
        setUser({ name: '', username: '', roleId: ''})
        setFormErrors([])
        document.getElementById("form-modal").reset();
        setCreateModalState(false)
        setEditModalState(false)
      }

     
   // Creating new user
   const handleCreateUser = () => {
    if (Object.keys(validate()).length === 0) { 
      createUser(user, dispatch).then(resp => {
        if (resp?.status === 200) {
          //handleCloseModal(dispatch)
          toast.success("Created successful!", { theme: "colored" })
          window.location.reload()
        }
      })
    }
  }
    

  // Updating user
  const handleUpdateUser = () => {
    if (Object.keys(validate()).length === 0) {
      updateUser(user, dispatch).then(resp => {
        if (resp?.status === 200) {
          // handleCloseModal(dispatch)
          toast.success("Updated successful!", { theme: "colored" })
          window.location.reload()
        }
        else {  }
      })
    }
  }



  const handleSuspendUser = (id, user) => {
    suspendUser(dispatch, id, user).then(resp => {
      if (resp?.status === 200) {
        // handleCloseModal(dispatch)
        toast.success("User suspended!", { theme: "colored" })
        // window.location.reload()
      }
      else {
        toast.error("Error occured!", { theme: "colored" })
      }
    })
  }

  const handleDeleteUser = (id, user) => {
    deleteUser(dispatch, id, user).then(resp => {
      if (resp?.status === 200) {
        // handleCloseModal(dispatch)
        toast.success("Deleted successful!", { theme: "colored" })
        window.location.reload()
      }
      else {
        toast.error("Error occured!", { theme: "colored" })
      }
    })
  }

    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title' style={{marginLeft: '11px'}}>Manage Users </p>
                </div>
                <div className='table-toolbar'>
                  <input type='text' className="search-table-input" placeholder='Search table...' />
                  <button type='button' className='create-btn' onClick={() => setCreateModalState(true)}>Create User</button>
                </div>
                
            </div>
            <div>

            {/* <div className='table-toolbar'>
                    
          </div> */}

        <div className='table-section'> 
           {allUsers?.length === 0? <div className='table-loading'><Loading /></div> : 
            <DataGrid rowHeight={40} rows={allUsers?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage + 1)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allUsers?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',height: '500px'}} 
                    />}
            </div>
        </div>



          {/* /////////////////////CREATING CONTACT //////////// */}
          <div className={createModalState ? 'create-modal-active' : 'create-modal-inactive'} >
                <form id="form-modal" className='view-modal-content'>
                  <p className='modal-title'> Create user
                    <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal()} /></p>
                  <div className="dialog-inputs">
                    <Input label="Name*" name='name' type='text' handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
                    <Input label="Email*" name='username' type='email' errorMessage={formErrors?.username}
                      handleChange={(e) => handleOnChange(e)} placeholder="email@gmail.com" />
                    <RoleSelectField errorMessage={formErrors?.roleId} handleChange={(e) => handleOnChange(e)} />
                  </div>
                  <div className='dialog-footer'>
                    <SubmitButton pending={action.pending} handleOnSubmit={handleCreateUser} />
                  </div>
                </form>
              </div>


          {/* /////////////////////EDITNG USER //////////// */}
          <div className={editModalState ? 'view-modal-active' : 'view-modal-inactive'}>
            <form id="form-modal" className='view-modal-content'>
              <p className='modal-title'> Edit user
                <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal()} /></p>
              <div className="dialog-inputs">
                <Input label="Name*" name='name' type='text' value={user?.name} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
                <Input label="Username*" name='username' type='email' value={user?.username} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.username}/>
                <RoleSelectField  value={user?.roleId} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.roleId}/>
              </div>
              <div className='dialog-footer'>
                <SubmitButton pending={action.pending} handleOnSubmit={handleUpdateUser} />
              </div>
            </form>
          </div>

          <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


     </div>
    )
}

export default UserManagement