import React, { useState, useEffect } from 'react';
import "./messageTemplate.css";
import { DataGrid} from '@mui/x-data-grid';

import Input from '../ReUsedComponents/inputField/Input';
import FileInput from '../ReUsedComponents/inputField/FileInput';
import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';
import TextArea from '../ReUsedComponents/textareaField/TextArea';
import SelectField from '../ReUsedComponents/selectField/SelectField';
import Loading from '../ReUsedComponents/loadingScreen/Loading';
import TemplatePreview from '../templatepreview/TemplatePreview';

import { getAllTemplates, createTemplate, getTemplateHeaderMediaID } from '../../apis/TemplatesAPI';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MessageTemplate = () => {

  const dispatch = useDispatch()
  const action = useSelector((state) => state.action)

  const [broadcastModalState, setBroadcastModalState] = useState(false);
  const [viewModalState, setViewModalState] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [pageNo, setPageNo] = useState(1);

  const [formErrors, setFormErrors] = useState()
  const [headerType, setHeaderType] = useState("TEXT")
  const [templateButtons, setTemplateButtons] = useState([])
  
  const [allTemplates, setAllTemplates] = useState([]);
  const [templateData, setTemplateData] = useState({
      name: '',
      language: 'en',
      category: '',
      component: {
          header: {
              format: headerType,
              text: '',
              example: "example1"
          },
          body: {
              text: '',
              examples: []
          },
          footer: {
              text: ''
          },
          buttons: null
      }
  });

  const [templatePreviewData, setTemplatePreviewData] = useState({
      name: '',
      language: 'en',
      category: '',
      component: {
          header: {
              format: headerType,
              text: '',
              example: "example1"
          },
          body: {
              text: '',
              examples: []
          },
          footer: {
              text: ''
          },
          buttons: null
      }
  });

  useEffect(() => {
      getAllTemplates(dispatch).then(resp => {
          if (resp?.status === 200) { setAllTemplates(resp?.data?.data); }
          else { toast.error("Error occured...", {theme: 'colored'}) }
        })
    }, [dispatch, pageSize, pageNo])


  // Effect to update templateData and templatePreviewData based on headerType
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const updatedTemplateData = {
      ...templateData,
      component: {
        ...templateData.component,
        header: {
          format: headerType,
          ...(headerType === 'TEXT' ? { text: '', example: "example1" } : { mediaId: '' }),
        },
      },
    };

    const updatedTemplatePreviewData = {
      ...templatePreviewData,
      component: {
        ...templatePreviewData.component,
        header: {
          format: headerType,
          ...(headerType === 'TEXT' ? { text: '', example: "example1" } : { text: '' }),
        },
      },
    };

    setTemplateData(updatedTemplateData);
    setTemplatePreviewData(updatedTemplatePreviewData);
  }, [headerType]);


// Effect to update templateData and templatePreviewData buttons
  useEffect(() => {
    const updatedButtons = templateButtons.map(button => (

      button.type === "PHONE_NUMBER"?
        { type: button.type,
          text: button.text,
          phoneNumber: button.phoneNumber,
          example: button.phoneNumber
        } :
        button.type === "URL"?
        { type: button.type,
          text: button.text,
          url: button.url,
          example: button.url
        } : 
        { type: button.type,
          text: button.text
        } 
    ));

    setTemplateData(prevState => ({
      ...prevState,
      component: {
        ...prevState.component,
        buttons: updatedButtons
      }
    }));

    setTemplatePreviewData(prevState => ({
      ...prevState,
      component: {
        ...prevState.component,
        buttons: updatedButtons
      }
    }));
  }, [templateButtons]);

  
  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setTemplateData(prevState => ({
          ...prevState,
          [name]: value
      }));
  };

  const handleComponentChange = (e, componentType, subField) => {
    const { value } = e.target;

    // Find all placeholders in the text
    const placeholderRegex = /\{\{(\w+)\}\}/g;
    const placeholders = [...value.matchAll(placeholderRegex)];

    // Create an examples array based on the number of placeholders
    const examples = placeholders.map((placeholder, index) => `example${index + 1}`);

    setTemplateData(prevState => {
        const updatedComponent = {
            ...prevState.component,
            [componentType]: {
                ...prevState.component[componentType],
                [subField]: value,
                ...(componentType === 'body' && { examples }),
            },
        };

        const headerFormat = updatedComponent.header.format;

        if (headerFormat === 'TEXT') {
            const { mediaId, ...headerWithoutMediaId } = updatedComponent.header;
            updatedComponent.header = {
                ...headerWithoutMediaId,
                example: 'example1',
            };
        } else {
            updatedComponent.header = {
                ...updatedComponent.header,
            };
        }

        const updatedData = {
            ...prevState,
            component: updatedComponent,
        };

        // Update both templateData and templatePreviewData
        setTemplatePreviewData(updatedData);
        return updatedData;
    });
};

  const handleHeaderTypeChange = (e) => {
      const newHeaderType = e.target.value;
      setHeaderType(newHeaderType);

      setTemplateData((prevState) => ({
          ...prevState,
          component: {
              ...prevState.component,
              header: {
                  ...prevState.component.header,
                  format: newHeaderType,
                  text: '' 
              }
          }
      }));

    };

  const validateTemplate = () => {
          let errors = {}
          if (!templateData?.name) {
            errors['name'] = 'Field is required!'
          }
          if (!templateData?.category) {
            errors['category'] = 'Field is required!'
          }
          if (!templateData?.component?.body?.text) {
            errors['message'] = 'Field is required!'
          }
          setFormErrors(errors)
          return errors
        };

  const handleCreateTemplate = () =>{
      if (Object.keys(validateTemplate()).length === 0) {
        createTemplate(dispatch, templateData).then(resp => {
          if (resp?.status === 200) {
            if (resp?.data.error === true) {
              toast.error(resp?.data.message, { theme: "colored" })
            } else {
              handleCloseModal(dispatch)
              toast.success("Created successful!", { theme: "colored" })
              window.location.reload()
            }
          }
          else {toast.error("Error occured", { theme: "colored" }) }
        })
      }
    }

  const handleViewTemplate = (template) => {
      setViewModalState(true)
      const componentsData = {
          header: {
              format: '',
              text: '',
              example: ''
          },
          body: {
              text: '',
              examples: []
          },
          footer: {
              text: ''
          }
      };

      template.components.forEach(component => {
          if (component.type === "HEADER") {
              componentsData.header.format = component.format;
              componentsData.header.text = component?.text;
              componentsData.header.example = component?.example?.header_text ? component?.example?.header_text[0] : '';
          } else if (component.type === "BODY") {
              componentsData.body.text = component.text;
              componentsData.body.examples = component?.example?.body_text ? component?.example?.body_text[0] : [];
          } else if (component.type === "FOOTER") {
              componentsData.footer.text = component.text;
          }
      });

      setTemplateData({
          name: template.name,
          language: "English",
          status: template.status,
          category: template.category,
          component: {
              header: componentsData.header,
              body: componentsData.body,
              footer: componentsData.footer,
              buttons: null // Add buttons logic here if needed
          }
      });
    };

  const handleCopyTemplateBody = (template) => {
      const bodyComponent = template.components.find(component => component.type === 'BODY');
      if (bodyComponent && bodyComponent.text) {
        navigator.clipboard.writeText(bodyComponent.text)
          .then(() => {
            toast.success('Copied successfully!');
          })
          .catch(err => {
            toast.error("Couldn't copy!");
          });
      } else {
        toast.error('No body text to copy!');
      }
    };

  const handleCloseModal = () => {
      setFormErrors([])
      document.getElementById("form-modal").reset();
      setBroadcastModalState(false)
      setViewModalState(false)
      setTemplateData({
        name: '',
        language: 'en',
        category: '',
        component: {
            header: {
                format: headerType,
                text: '',
                example: "example1"
            },
            body: {
                text: '',
                examples: []
            },
            footer: {
                text: ''
            },
            buttons: null
        }
    });
    }

  const columns = [
      { field: 'name', headerName: 'Name', flex: 1, minWidth: 200, headerClassName: 'custom-table-header', sortable: false },
      { field: 'category', headerName: 'Category', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: true },
      { field: 'language', headerName: 'Language', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false },
      { field: 'status', headerName: 'Status', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: true,
        renderCell: (param) => <span id={`status-${param.value}`}>{param.value}</span> },
      { field: 'rejected_reason', headerName: 'Rejection Reason', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: false,
        renderCell: (param) => param.row.status === "REJECTED"? param.row.rejected_reason : "-"},
      { field: 'createdAt', headerName: 'Created At', flex: 1, minWidth: 150, headerClassName: 'custom-table-header', sortable: true,
        renderCell: (param) => <span>{dateFormat(param.row.createdAt, "dd/mm/yyyy HH:MM")}</span>},
      { field: 'actions', headerName: 'Actions', width: 150, headerClassName: 'custom-table-header', sortable: false,
          renderCell: (param) => (
              <div className='contacts-table-actions'>
                <i class="pi pi-eye" id="view-action-icon" onClick={()=>handleViewTemplate(param.row)}/>
                <i class="pi pi-copy" id="view-action-icon" onClick={()=>handleCopyTemplateBody(param.row)}/>
                <i class="pi pi-trash" id="delete-action-icon" />
              </div>
          )
      },
    ];

  const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
    
      try {
        getTemplateHeaderMediaID(dispatch, formData).then((resp) => {
          if (resp?.status === 200) {
            if (resp?.data.error === true) {
              toast.error(resp?.data.message, { theme: "colored" });
            } else {
                setTemplateData((prevData) => {
                  const newHeader = {
                    ...prevData.component.header,
                    mediaId: resp?.data?.data, // Add the mediaId
                  };
    
                // Remove text and example only if mediaId is present
                delete newHeader.text;
                delete newHeader.example;
    
                return {
                  ...prevData,
                  component: {
                    ...prevData.component,
                    header: newHeader, // Set the updated header
                  },
                };
              });


              const fileUrl = URL.createObjectURL(file);
              let fileFormat = 'None'; // Default to IMAGE
              const fileType = file.type.split('/')[0]; // e.g., 'image', 'video', 'application'
    
              // Determine the file format
              if (fileType === 'image') {
                fileFormat = 'IMAGE';
              }
              if (fileType === 'video') {
                fileFormat = 'VIDEO';
              } else if (fileType === 'application') {
                fileFormat = 'DOCUMENT'; // Assuming this for documents
              }
             
              // Update templatePreviewData
              setTemplatePreviewData((prevData) => ({
                ...prevData,
                component: {
                  ...prevData.component,
                  header: {
                    ...prevData.component.header,
                    text: fileUrl, // Set the file name as the header text
                    format: fileFormat, // Set the file format here as well
                  }
                }
              }));

            }
          } else {
            toast.error("Error uploading file", { theme: "colored" });
          }
        });
      } catch (error) {
          toast.error("Error uploading file", { theme: "colored" });
      }
    };

  const handleAddTemplateButton = () => {
        setTemplateButtons([...templateButtons, {type: '', text: ''}])
    };

  const handleRemoveTemplateButton = (typeToRemove, textToRemove) => {
      const newButtons = templateButtons.filter(
        btn => !(btn.type === typeToRemove && btn.text === textToRemove)
      );
      setTemplateButtons(newButtons); // Update the state with the filtered array
    };

  const handleButtonsInputChange = (index, field, value) => {
      const updatedButtons = [...templateButtons];
      updatedButtons[index][field] = value;
      setTemplateButtons(updatedButtons);
    };

    console.log(templateData)


    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title' style={{marginLeft: '11px'}}>Template Messages</p>
                </div>
                <div className='table-toolbar'>
                  <input type='text' className="search-table-input" placeholder='Search table...' />
                  <div>
                  <button type='button' className='create-btn' onClick={() => setBroadcastModalState(true)}>Create Template</button>
                  </div>
                </div>
                
            </div>
            <div>

        <div className='table-section'> 
           {allTemplates?.length === 0? <div className='table-loading'><Loading /></div> : 
            <DataGrid rowHeight={40} rows={allTemplates?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={allTemplates?.data?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',height: '500px'}} 
                    />}
            </div>
          </div>

      {/* ///// CREATING TEMPLATE  */}
      <div className={broadcastModalState ? 'template-create-modal-active' : 'template-create-modal-inactive'} >
        <div className='create-whatsapp-template-modal'>
            <form id="form-modal" className='template-view-modal-content create-template-form'>
              <p className='broadcast-modal-title'> Create Template
                <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal(dispatch)} /></p>
              <div className="template-broadcast-dialog">
                
                 {/* add template meta data section */}
                <div className='template-dialog-inputs'>
                  <Input id="template-input" label="Template Name" name='name' type='text' handleChange={handleInputChange} errorMessage={formErrors?.name} 
                     description="Example: test_template"/>
                  <SelectField label="Category" placeholder="Select Category" name="category" selects={['UTILITY', 'MARKETING']}
                    handleChange={handleInputChange} errorMessage={formErrors?.category}/>
                  <Input id="template-input" label="Language" type='text' handleChange={handleInputChange} value="English" disabled='true' />
                </div>
                
                 {/* add template content section */}
                <div className='template-dialog-inputs-title'>
                  <SelectField label="Header (Optional)" value={headerType} selects={['TEXT', "IMAGE", "VIDEO", "DOCUMENT"]}
                    handleChange={handleHeaderTypeChange} />
                    {
                    templateData.component.header.format === "TEXT"? (
                     <Input id="template-input" name='text' type='text' handleChange={(e) => handleComponentChange(e, 'header', 'text')}
                        placeholder="Enter Text" value={templateData.component.header.text} 
                        description="Title can only have one variable."/>)
                    
                    :templateData.component.header.format === "IMAGE"? (
                     <FileInput label="Choose PNG or JPG file" name='image' handleChange={handleFileUpload}
                     accept="image/*" uploading={action.pending} selectedFile={templateData.component.header.text}/>)
                    
                    :templateData.component.header.format === "VIDEO"? (
                     <FileInput label="Choose MP4 file" name='video' handleChange={handleFileUpload}
                     accept="video/mp4, video/webm" uploading={action.pending} selectedFile={templateData.component.header.text} />)

                    :templateData.component.header.format === "DOCUMENT"?(
                     <FileInput label="Choose PDF file" name='document' type='file' handleChange={handleFileUpload}
                     accept=".pdf,.doc,.docx,.xlsx" uploading={action.pending} selectedFile={templateData.component.header.text} />)

                    : null
                    }      
                </div>

                <div>
                  <TextArea label="Body" name='message' type='text' handleChange={(e) => handleComponentChange(e, 'body', 'text')}
                          error={formErrors?.message} value={templateData.component.body.text} 
                          moreDeatials="To add a variable, please add a variable in curly brackets without a space, Example: {{name}}"
                         />
                    <div className='add-variable-btn'>
                     <i className="pi pi-plus" style={{ fontSize: '0.8rem', marginRight: '5px' }}></i>Add variable</div>
                </div>

                <div className='template-dialog-inputs-title'>
                  <h5>Footer (Optional)</h5>
                  <Input id="template-input" name='footer' type='text' handleChange={(e) => handleComponentChange(e, 'footer', 'text')}
                      placeholder="Enter Text" value={templateData.component.footer.text} />
                </div>
                 
                 {/* add template buttons section */}
                <div className='template-dialog-inputs-title'>
                    <h5>Buttons (Optional)</h5>
                    <p style={{ fontSize: '12px', fontWeight: '600' }}>Create upto 3 buttons that lets your customer respond to your message or take action</p>
                    {templateButtons.length === 3? (
                       <div className='add-buttons-btn-disabled' disabled='true'>
                    <i className="pi pi-plus" style={{ fontSize: '0.8rem', marginRight: '5px'}}></i>Add Button</div>
                    ):
                    (<div className='add-buttons-btn' onClick={handleAddTemplateButton}>
                    <i className="pi pi-plus" style={{ fontSize: '0.8rem', marginRight: '5px' }}></i>Add Button</div>)
                    }
                  
                   {templateButtons.map((btn, index)=>(
                    <div className='template-buttons-list' key={index}>
                      <div className='added-temp-btns'>
                        <SelectField id="template-button-type" placeholder="Select Type" selects={['QUICK_REPLY', 'PHONE_NUMBER', 'URL']}
                        value={btn.type} name="type"
                        onChange={(e) => handleButtonsInputChange(index, 'type', e.target.value)}/>

                        <Input id="template-button-text" type='text' placeholder="Enter Text" 
                        value={btn.text} name="text"
                        onChange={(e) => handleButtonsInputChange(index, 'text', e.target.value)}/>  

                        {btn.type === 'PHONE_NUMBER' ? (
                            <Input id="template-button-text" type='number' placeholder="Enter Phone number" 
                            value={btn.phoneNumber} name="phoneNumber"
                            onChange={(e) => handleButtonsInputChange(index, 'phoneNumber', e.target.value)}/>  
                          ):
                        btn.type === 'URL'?(
                        <Input id="template-button-text" type='text' placeholder="Enter Url" 
                        value={btn.url} name="url"
                        onChange={(e) => handleButtonsInputChange(index, 'url', e.target.value)}/>
                          ): ''
                        }
                      </div>
                      <i id='remove-temp-button' className="pi pi-times" style={{ fontSize: '0.8rem', marginRight: '5px' }} 
                         onClick={ () => handleRemoveTemplateButton(btn.type, btn.text)}></i>
                      </div>
                    ))}
              
                </div>

                
              </div>
              <div className='dialog-footer'>
              <SubmitButton pending={action.pending} handleOnSubmit={handleCreateTemplate} />  
              </div>
            </form>

            <TemplatePreview templatePreview={templatePreviewData}/>
          </div>

          </div>


      {/* ///// VIEW TEMPLATE  */}
      <div className={viewModalState ? 'template-create-modal-active' : 'template-create-modal-inactive'} >
        <form id="form-modal" className='template-view-modal-content template-form'>
          <p className='broadcast-modal-title'> Template Message
            <i id='close-button' className="pi pi-times" onClick={() => handleCloseModal(dispatch)} /></p>
            <div className="broadcast-dialog">
              <div className='template-dialog-inputs'>
                <Input id="template-input" label="Template Name" value={templateData?.name} disabled='true'/>
                <Input label="Category" value={templateData?.category} disabled/>
                <Input id="template-input" label="Language" value="English" disabled='true'/>
              </div>

              <Input id="template-input" label="Title" value={templateData.component?.header?.text} disabled='true'/>
              <TextArea label="Body" defaultValue={templateData.component?.body?.text} readOnly='true' />
              <Input id="template-input" label="Footer" value={templateData.component?.footer?.text ? templateData.component?.footer?.text : '--'} disabled='true' />
              
              </div>
        
        </form>
      </div>


      <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />
    
        </div>
    )
}

export default MessageTemplate